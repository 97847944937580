import {
  Box,
  Collapse,
  Grid,
  Card as CardMUI,
  CardContent,
  Typography,
  Avatar,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import {
  countBy,
  filter,
  find,
  get,
  includes,
  indexOf,
  isEmpty,
  join,
  map,
  max,
  noop,
} from "lodash";
import React, { useMemo, useState } from "react";
import {
  isRadioButtonGroup,
  shouldEnableMultiSelect,
  priceForSequenceOption,
} from "../../utils/menuItemOrderData";
import Card from "../Card";
import ExpandMore from "../expand-more";
import TastePreferenceDecorator from "./TastePreferenceDecorator";
import * as styles from "./index.module.scss";
import Image from "../image";
import NoImageRenderer from "../no-image-renderer";
import { mediaTypes } from "../../utils/media";
import QuantityInput from "../quanitity-input";
import ModifierWrapper from "./modifiers";
import ComponentOptionPrice from "./ComponentOptionPrice";

export default function OrderItemComponent(props) {
  const { orderItemComponent, onChange = noop, posInnerMenuId } = props;
  const {
    appStyles,
    pageContext: {
      business: { currencySymbol, allergens },
      kioskMode,
    },
    T,
    firstComponentWithError,
    animateErrorElement,
    isMobile,
  } = props;

  const {
    maxNumOfMarkedOptionsIncluded,
    minNumOfMarkedComponentOptions,
    maxNumOfMarkedOptions,
    chosenOrderItemComponentOptions,
    priceAdditional,
    sequencePrices,
  } = orderItemComponent;

  const [displayRadioButtonGroup, enableMultiSelect] = useMemo(
    () => [
      isRadioButtonGroup(orderItemComponent),
      shouldEnableMultiSelect(orderItemComponent),
    ],
    [JSON.stringify(orderItemComponent)]
  );
  const limit = useMemo(
    () => {
      const value = Math.max(maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded);
      if (value < 0){
        return undefined;
      }
      return value;
    },
    [maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded]
  );

  const getMaxForComponentOption = (componentOption) => {
    return max([maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded]) > -1
      ? max([maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded]) -
          filter(
            chosenOrderItemComponentOptions,
            (id) => id !== componentOption.id
          ).length
      : 100;
  };

  const shouldShowPlusSign = !(
    minNumOfMarkedComponentOptions > 0 &&
    !find(
      orderItemComponent.componentOptions,
      (componentOption) => !componentOption.price
    )
  );

  const [expanded, setExpanded] = useState(
    (appStyles.expandModifiersIfLessThan > 0 &&
      appStyles.expandModifiersIfLessThan >
        orderItemComponent.componentOptions.length) ||
      minNumOfMarkedComponentOptions > 0 ||
      !appStyles.collapseModifiersByDefault
  );

  const choesenCounter = countBy(
    orderItemComponent.chosenOrderItemComponentOptions
  );

  const chosenTitles = useMemo(
    () =>
      map(
        filter(orderItemComponent.componentOptions, ({ id }) =>
          includes(orderItemComponent.chosenOrderItemComponentOptions, id)
        ),
        ({ title, id }) => {
          const count = choesenCounter[id];
          if (count > 1) {
            return `${count} × ${T(title)}`;
          }
          return T(title);
        }
      ),
    [JSON.stringify(orderItemComponent.chosenOrderItemComponentOptions)]
  );

  const mainHeader = includes(orderItemComponent.title, "(")
    ? orderItemComponent.title.substring(
        0,
        indexOf(orderItemComponent.title, "(")
      )
    : orderItemComponent.title;
  const sideHeaderNote = includes(orderItemComponent.title, "(")
    ? orderItemComponent.title.substring(indexOf(orderItemComponent.title, "("))
    : null;

  const getVariant = () => {
    if (displayRadioButtonGroup) {
      return "radio";
    }
    if (enableMultiSelect) {
      return "multiple";
    }
    return "checkbox";
  };

  const optionsHaveImage = get(orderItemComponent, "componentOptions")?.some(
    (option) => option.imageKey
  );

  const itemModifier = get(appStyles, "modifierImageConfig.useModifierImages") && optionsHaveImage ? (
    <ModifierWrapper
      orderItemComponent={orderItemComponent}
      variant={getVariant()}
      limit={limit < 0 ? undefined : limit}
      onChange={onChange}
      isMobile={isMobile}
      appStyles={appStyles}
      allergens={allergens}
      T={T}
      shouldShowPlusSign={shouldShowPlusSign}
      currencySymbol={currencySymbol}
      posInnerMenuId={posInnerMenuId}
    />
  ) : displayRadioButtonGroup ? (
      <Card.Content.RadioGroup
        value={
          Array.isArray(orderItemComponent.chosenOrderItemComponentOptions) &&
          orderItemComponent.chosenOrderItemComponentOptions.length
            ? orderItemComponent.chosenOrderItemComponentOptions[0]
            : null
        }
        onChange={(chosenOrderItemComponentOption) =>
          onChange({
            ...orderItemComponent,
            chosenOrderItemComponentOptions: [chosenOrderItemComponentOption],
          })
        }
        wrap
        appStyles={appStyles}
      >
        {map(orderItemComponent.componentOptions, (componentOption, index) => (
          <Card.Content.RadioOption
            iconSize={16}
            iconInnerSize={16}
            pointColor={appStyles.actionColor}
            value={componentOption.id}
            key={componentOption.id}
          >
            <span style={{ paddingTop: 4, width: "100%" }}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TastePreferenceDecorator
                  title={T(componentOption.title)}
                  appStyles={appStyles}
                  allergens={allergens}
                />
                <ComponentOptionPrice
                  componentOption={componentOption}
                  addPlusSign={
                    shouldShowPlusSign && !appStyles.hidePlusSign
                  }
                  appStyles={appStyles}
                  currencySymbol={currencySymbol}
                  posInnerMenuId={posInnerMenuId}
                  orderItemComponent={orderItemComponent}
                />
              </span>
              {componentOption.description && (
                <span>
                  <small style={{ color: "#555" }}>
                    {T(componentOption.description)}
                  </small>
                </span>
              )}
            </span>
          </Card.Content.RadioOption>
        ))}
      </Card.Content.RadioGroup>
    ) : enableMultiSelect ? (
      <ModifierWrapper
        orderItemComponent={orderItemComponent}
        variant={getVariant()}
        limit={limit < 0 ? undefined : limit}
        onChange={onChange}
        isMobile={isMobile}
        appStyles={appStyles}
        allergens={allergens}
        T={T}
        shouldShowPlusSign={shouldShowPlusSign}
        currencySymbol={currencySymbol}
        posInnerMenuId={posInnerMenuId}
        layout="horizontal"
        withImage={false}
      />
    ) : (
      <Card.Content.CheckboxGroup
        name={T(orderItemComponent.title)}
        limit={limit < 0 ? undefined : limit}
        checked={orderItemComponent.chosenOrderItemComponentOptions || []}
        onChange={(chosenOrderItemComponentOptions) =>
          onChange({
            ...orderItemComponent,
            chosenOrderItemComponentOptions,
          })
        }
        wrap
        appStyles={appStyles}
      >
        {map(orderItemComponent.componentOptions, (componentOption, index) => (
          <Card.Content.CheckboxOption
            iconSize={16}
            iconInnerSize={16}
            pointColor={appStyles.actionColor}
            value={componentOption.id}
            key={componentOption.id}
          >
            <span style={{ paddingTop: 4, width: "100%" }}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TastePreferenceDecorator
                  title={T(componentOption.title)}
                  appStyles={appStyles}
                  allergens={allergens}
                />
                <ComponentOptionPrice
                  componentOption={componentOption}
                  addPlusSign={
                    shouldShowPlusSign && !appStyles.hidePlusSign
                  }
                  appStyles={appStyles}
                  currencySymbol={currencySymbol}
                  posInnerMenuId={posInnerMenuId}
                  orderItemComponent={orderItemComponent}
                />
              </span>
              {componentOption.description && (
                <span>
                  <small style={{ color: "#555" }}>
                    {T(componentOption.description)}
                  </small>
                </span>
              )}
            </span>
          </Card.Content.CheckboxOption>
        ))}
      </Card.Content.CheckboxGroup>
    );

  return (
    <Card
      style={{ paddingBottom: expanded ? 10 : 10, cursor: "pointer" }}
      appStyles={appStyles}
      key={orderItemComponent.id}
      refEl={props.refEl}
      animateError={firstComponentWithError && animateErrorElement}
      kioskMode={kioskMode}
      hasErrors={firstComponentWithError}
    >
      <Card.Header onClick={() => setExpanded(!expanded)}>
        <Grid container direction="column" spacing={1} alignItems="flex-start">
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Card.Header.Title appStyles={appStyles}>
                {T(mainHeader)}
              </Card.Header.Title>
              {sideHeaderNote && (
                <Card.Header.SideNote appStyles={appStyles}>
                  {T(sideHeaderNote)}
                </Card.Header.SideNote>
              )}
            </Grid>
            <Grid item>
              <Card.Header.SideNote appStyles={appStyles}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  wrap="nowrap"
                >
                  {limit > 0 && !displayRadioButtonGroup && (
                    <Grid item>
                      <small>
                        {T("max.")} {limit}
                      </small>
                    </Grid>
                  )}
                  {minNumOfMarkedComponentOptions > 0 && (
                    <Grid item>
                      <strong style={{ color: appStyles.actionColor }}>
                        {T("Required")}
                      </strong>
                    </Grid>
                  )}
                  <Grid item>
                    <Box sx={{ marginRight: 0.5 }}>
                      <ExpandMore
                        expanded={expanded}
                        handleExpandClick={() => setExpanded(!expanded)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Card.Header.SideNote>
            </Grid>
          </Grid>
          <Grid item>
            <Collapse in={!expanded}>
              <small>{join(chosenTitles, ", ")}</small>
            </Collapse>
          </Grid>
        </Grid>
      </Card.Header>
      <Collapse in={expanded}>
        <Card.Content style={{padding: "12px"}}>{itemModifier}</Card.Content>
      </Collapse>
    </Card>
  );
}
