import React from "react";
import { Box, Typography, Checkbox, Divider } from "@mui/material";
import { filter, get } from "lodash";
import Image from "../../image";
import { mediaTypes } from "../../../utils/media";
import NoImageRenderer from "../../no-image-renderer";
import TastePreferenceDecorator from "../TastePreferenceDecorator";
import { Check } from "@mui/icons-material";
import QuantityInput from "../../quanitity-input";
import ComponentOptionPrice from "../ComponentOptionPrice";

const ImageComponent = ({ componentOption, appStyles }) => {
  const imageProps = {
    sizes: "60px",
    wrapperStyle: {
      height: 60,
      width: 60,
      borderRadius: "50%", // Optional, to give rounded corners
      boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.10)",
      background: appStyles.backgroundColor,
    },
  };

  return (
    <div
      style={{
        height: "auto",
        display: "flex",
      }}
    >
      {get(componentOption, "imageKey") ? (
        <Image
          mediaKey={componentOption.imageKey}
          mediaType={mediaTypes.menuItemModifier}
          imagePreview={componentOption.imagePreview}
          {...imageProps}
          imgStyle={{ objectFit: "contain" }}
        />
      ) : (
        <NoImageRenderer appStyles={appStyles} {...imageProps} />
      )}
    </div>
  );
};

const ModifierItem = ({
  index,
  componentOption,
  onClick,
  selected,
  disabled,
  variant,
  appStyles,
  T,
  allergens,
  shouldShowPlusSign,
  orderItemComponent,
  currencySymbol,
  posInnerMenuId,
  limit,
  isMobile,
  withImage
}) => {
  const getMax = (limit) => {
    const lengthOfThisComponentOption = filter(
      orderItemComponent.chosenOrderItemComponentOptions,
      (val) => val === componentOption.id
    ).length;
    return lengthOfThisComponentOption < limit ? limit -  
      (orderItemComponent.chosenOrderItemComponentOptions.length - 
        lengthOfThisComponentOption):
          limit > 0 ? limit - (
            orderItemComponent.chosenOrderItemComponentOptions ?
            orderItemComponent.chosenOrderItemComponentOptions.length : 0) : 100
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      key={index}
    >
      <Box
        sx={{
          padding: "16px 12px 16px 3px",
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            if (disabled) return;

            if (selected) {
              onClick(0);
            } else {
              onClick(1);
            }
          }}
        >
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                onClick(1);
              } else {
                onClick(0);
              }
            }}
            disabled={disabled}
            checked={selected}
            sx={{ marginRight: "9px" }}
            checkedIcon={
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "6px",
                  width: 20,
                  height: 20,
                  border: `2px solid ${appStyles.actionColor}`,
                  backgroundColor: `${appStyles.actionColor}1A`,
                }}
              >
                <Check
                  sx={{ fontSize: "22px", color: appStyles.actionColor }}
                />
              </Box>
            }
            icon={
              <Box
                component="span"
                sx={{
                  borderRadius: "6px",
                  border: `2px solid ${appStyles.actionColor}40`,
                  width: 20,
                  height: 20,
                  // backgroundColor: appStyles.backgroundColor,
                }}
              />
            }
          />
          {withImage && <ImageComponent
            componentOption={componentOption}
            appStyles={appStyles}
          />}
          <Box
            sx={{
              padding: "0 8px 0 12px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TastePreferenceDecorator
              title={T(componentOption.title)}
              appStyles={appStyles}
              allergens={allergens}
              titleStyles={{
                fontWeight: "bold",
                color: appStyles.textColor,
                fontSize: isMobile ? "14px" : "18px",
              }}
            />
            {componentOption.description && (
              <Typography
                variant="body2"
                component="span"
                sx={{ color: "text.secondary", fontSize: isMobile ? "12px" : "16px" }}
              >
                {T(componentOption.description)}
              </Typography>
            )}
            <ComponentOptionPrice
              componentOption={componentOption}
              addPlusSign={shouldShowPlusSign && !appStyles.hidePlusSign}
              appStyles={appStyles}
              currencySymbol={currencySymbol}
              posInnerMenuId={posInnerMenuId}
              orderItemComponent={orderItemComponent}
            />
          </Box>
        </Box>
        {selected && variant === "multiple" && (
          <QuantityInput
            size="small"
            appStyles={appStyles}
            readOnly
            value={
              filter(
                orderItemComponent.chosenOrderItemComponentOptions,
                (val) => val === componentOption.id
              ).length
            }
            min={0}
            max={  
                 getMax(limit)
              }
            onChange={onClick}
          />
        )}
      </Box>
      {index !== "last" && <Divider sx={{ width: "100%" }} />}
    </Box>
  );
};

export default ModifierItem;
